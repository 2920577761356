import { auth } from '@/firebase/client';
import { signInWithEmailAndPassword } from 'firebase/auth';

export const loginWithCredentials = async ( email: string, password: string ) => {
	const { user } = await signInWithEmailAndPassword( auth, email, password );
	if ( !user?.emailVerified ) {
		const userCreationDate = new Date( user.metadata.creationTime! ).getTime();
		const twoDaysAgo = new Date().getTime() - 2 * 24 * 60 * 60 * 1000;
		if ( userCreationDate < twoDaysAgo ) {
			throw 'auth/email-not-verified';
		}
	}
	
	return user;
};
